import { Center, Text } from "@chakra-ui/layout";

interface ComponentProps {
  eventName: string
}

export const EventNameHeader = (props: ComponentProps) => {
  const { eventName } = props;
  return (
    <Center>
      <Text fontSize="24" m="3">
        {eventName}
      </Text>
    </Center>
  );
}