import { Box, Center } from "@chakra-ui/layout";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { ROUTES, toEvent } from "../../routes";
import { EventNameHeader } from "../components/EventNameHeader";
import { Loading } from "../components/Loading";
import { CheckSecretSantaAlert } from "./CheckSecretSantaAlert";
import { CheckSecretSantaComponent } from "./CheckSecretSantaComponent";
import { Event, EventPairResponse, checkpair, getEvent } from "../../api";

export const CheckSecretSantaPage = () => {
  const navigate = useNavigate();
  const { eventId, userId } = useParams();
  const [event, setEvent] = useState<Event>();
  const [checkedPairData, setCheckedPairData] = useState<EventPairResponse>();

  const onBack = () => {
    navigate(toEvent(eventId));
  };

  const onCheckPair = () => {
    checkpair(userId!).then(pair => {
      setCheckedPairData(pair)
    })
  };

  useEffect(() => {
    getEvent(eventId!).then(setEvent)
  }, [eventId])

  const getUserName = useCallback(() => {
    if (!event) {
      return;
    }
    return event.participants.find((p) => p.id === userId)?.name;
  }, [event, userId]);

  if (!event) {
    return <Loading />;
  }

  return (
    <Center>
      <Box>
        <EventNameHeader eventName={event.name} />
        {checkedPairData ? 
          <CheckSecretSantaComponent
            eventName={event.name}
            eventId={event.id}
            giverName={checkedPairData.giver.name}
            giftedName={checkedPairData.gifted.name!}
            onBack={onBack}
          />
         : 
          <CheckSecretSantaAlert
            userName={getUserName()}
            onAccept={onCheckPair}
            onCancel={onBack}
          />
        }
      </Box>
    </Center>
  );
};
