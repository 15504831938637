import { Button } from "@chakra-ui/react";

interface ComponentProps {
  name: string
  onClick?: () => void;
}

export const SubmitButton = (props: ComponentProps) => {
  const { name, onClick } = props;
  return (
    <Button size="lg" m="6" type="submit" onClick={onClick}>
      {name}
    </Button>
  );
}
