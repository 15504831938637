import { Box, Center, Text } from "@chakra-ui/layout";
import { Progress, Stat, StatGroup, StatLabel, StatNumber } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { ROUTES, toEventCheck } from "../../routes";
import { EventNameHeader } from "../components/EventNameHeader";
import { Loading } from "../components/Loading";
import { ParticipantRow } from "../components/ParticipantRow";
import { SubmitButton } from "../components/SubmitButton";
import { Event, getEvent } from "../../api";

export const EventPage = () => {
  const navigate = useNavigate();
  const { eventId } = useParams();
  const [event, setEvent] = useState<Event>()

  useEffect(() => {
    getEvent(eventId!).then(setEvent)
  }, [eventId])

  const onCheck = () => {
    navigate(toEventCheck(eventId));
  };

  return !event ? (
    <Loading />
  ) : (
    <Box>
      <EventNameHeader eventName={event.name} />

      <StatGroup my="3">
        <Stat size="lg" alignSelf={"end"}>
          <StatLabel textAlign={"center"}>Event date</StatLabel>
          <StatNumber textAlign={"center"} fontSize={28}>{event.date.substring(0, 10)}</StatNumber>
          {/* <StatHelpText>Feb 12 - Feb 28</StatHelpText> */}
        </Stat>
        <Stat size="lg">
          <StatLabel textAlign={"center"}>Maximum amount that can be spent on a gift</StatLabel>
          <StatNumber textAlign={"center"} fontSize={28}>{event.limit.toFixed(2)}</StatNumber>
          {/* <StatHelpText>Feb 12 - Feb 28</StatHelpText> */}
        </Stat>
      </StatGroup>

      <Progress
        my="6"
        size="lg"
        value={
          (event.participants.filter((p) => p.checked_pair).length! /
            event.participants.length!) *
          100
        }
      />

      <Box my="3">
        {event.participants.map((participant) => (
          <ParticipantRow
            name={participant.name}
            checked={participant.checked_pair}
          />
        ))}
      </Box>

      <Center>
        {event.participants.some((p) => !p.checked_pair) ? (
          <SubmitButton name="Check your Secret Santa" onClick={onCheck} />
        ) : (
          <Text m="6" color="green">
            All participants has checked their Secret Santa.
          </Text>
        )}
      </Center>
    </Box>
  );
}
