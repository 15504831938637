const API_LINK = "https://letsbesanta-serverless.vercel.app/api";

export type EventInput = {
    name: string;
    date: string;
    limit: number;
    participants: string[];
}

export type Event = {
    id: string;
    name: string;
    date: string;
    limit: number;
    participants: Participant[];
}

export type Participant = {
    id: string;
    name: string;
    event_id: string;
    checked_pair: boolean;
    checked_pair_at?: Date | null;
}

export type EventPairResponse = {
  giver: Participant;
  gifted: Participant;
}

const santaFetch = async <T>(url: string, method?: string, body?: any): Promise<{ data?: T, err?: string }> => {
    try {
      const resp = await fetch(url, {method: method || "GET", body: body });
      if (!resp.ok) {
        throw new Error(`Response not ok. Status: ${resp.status}`)
      }
      return { data: await resp.json() }
    } catch (e) {
      const err = `Request ${url} failed with error: ${e}`
      console.error(err)
      return { err }
    }
  }

export const registerEvent = async (event: EventInput): Promise<Event|undefined> => {
    const response = await santaFetch<Event>(API_LINK + "/register-event", "POST", JSON.stringify(event))
    if (response.err || !response.data) {
        return;
      }
    return response.data
}

export const getEvent = async (id: string): Promise<Event|undefined> => {
    const response = await santaFetch<Event>(API_LINK + "/get-event?" + new URLSearchParams({id: id}))
    if (response.err || !response.data) {
        return;
      }
    return response.data
}

export const checkpair = async (id: string): Promise<EventPairResponse|undefined> => {
    const response = await santaFetch<EventPairResponse>(API_LINK + "/check-pair?" + new URLSearchParams({id: id}))
    if (response.err || !response.data) {
        return;
      }
    return response.data
}

