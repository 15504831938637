import { Box, Center, Text } from "@chakra-ui/layout";
import { Alert, AlertDescription, AlertIcon, AlertTitle } from "@chakra-ui/react";
import { SubmitButton } from "../components/SubmitButton";

interface ComponentProps {
  eventName: string;
  eventId: string;
  giverName: string;
  giftedName: string;
  onBack: () => void;
}

export const CheckSecretSantaComponent = (props: ComponentProps) => {
  const { giverName, giftedName, onBack } = props;
  return (
    <Box>
      <Alert
        status="success"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          {giverName}, you are Santa for
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          <Text my="3" fontSize="32">{giftedName}</Text>
        </AlertDescription>
      </Alert>
      <Center>
        <SubmitButton name="Go back to event page" onClick={onBack} />
      </Center>
    </Box>
  );
}