import { Box, Text, Button, Center, Link, Alert, AlertIcon, SimpleGrid } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { toEvent } from "../../routes";

export interface CreatedEventProps {
  eventName: string;
  eventId: string;
}

export const CreatedEventComponent = (props: CreatedEventProps) => {
  const { eventName, eventId } = props;
  const navigate = useNavigate();
  const eventLink = window.location.href + "/" + eventId

  const onGoToEvent = () => {
    navigate(toEvent(eventId));
  }

  const onShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: `Check out "${eventName}" on Let's be Santa!`,
          text: `Check out "${eventName}" on Let's be Santa! Who will you be a Santa for?`,
          url: eventLink,
        })
        .then(() => {
          console.log("Successfully shared");
        })
        .catch((error) => {
          console.error("Something went wrong sharing the blog", error);
        });
    }
  }

  return (
    <Box maxWidth="lg">
      <Center my="3">
        <Text fontSize="22">
          Congratulations your event <b>{eventName}</b> is successfully
          created.
        </Text>
      </Center>
      <Center my="3">
        <Text>
          Now you should send link below to all participants to let them know
          who is their Secret Santa!
        </Text>
      </Center>
      <Center my="6">
        <Link size="lg" color="teal.500" href={eventLink}>
          {eventLink}
        </Link>
      </Center>
      <SimpleGrid columns={[1, 2]}>
        <Button
          m="3"
          colorScheme="green"
          onClick={onGoToEvent}
        >
          Go to event Page
        </Button>
        <Button m="3" onClick={onShare}>
          Share event!
        </Button>
      </SimpleGrid>
      <Center my="3">
        <Alert status="info">
          <AlertIcon />
          <Box>
            <Text fontWeight="bold">Remember!</Text>
            <Text>
              Each participant has only one chance to see their pair to avoid
              cheating ;)
            </Text>
          </Box>
        </Alert>
      </Center>
    </Box>
  );
}
