import { Box, Center, Text } from "@chakra-ui/layout";
import { Alert, AlertDescription, AlertIcon, AlertTitle, Button, SimpleGrid } from "@chakra-ui/react";
import { Loading } from "../components/Loading";

interface ComponentProps {
  userName?: string;
  onAccept: () => void;
  onCancel: () => void;
}

export const CheckSecretSantaAlert = (props: ComponentProps) => {
  const { userName, onAccept, onCancel } = props;
  return (
    userName ?
    <Box>
      <Center>
        <Text fontSize="20" fontWeight="bold">
          {"Confirm that, you’re " + userName}
        </Text>
      </Center>
      <Center my="6">
        <Alert
          status="info"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Remember!
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            {"Are you sure you want to check the pair of user "
              + userName
              + "? This can be done only once. If it's not you the real user won't find out who is his/her Secret Santa."}
          </AlertDescription>
        </Alert>
      </Center>
      <Center>
        <SimpleGrid columns={[1, 2]}>
          <Button m="3" colorScheme="green" onClick={onAccept}>
            {"Yes, I’m " + userName}
          </Button>
          <Button m="3" colorScheme="red" onClick={onCancel}>
            No, it’s not me!
          </Button>
        </SimpleGrid>
      </Center>
    </Box> : <Loading />
  );
}