import { Center, Text } from "@chakra-ui/layout";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { ROUTES } from "../routes";
import { SubmitButton } from "./components/SubmitButton";

export const WelcomePage = () => {
  const navigate = useNavigate();

  const onOrganizeHandle = () => {
    navigate(ROUTES.CREATE_EVENT);
  }

  return (
    <Center flexDirection={"column"}>
      <Text align={"center"}>Want to play Secret Santa with friends or family?</Text>
      <Text align={"center"}>Don't want to share your contact details?</Text>
      <form onSubmit={onOrganizeHandle}>
        <SubmitButton name="Organize an event!"/>
      </form>
    </Center>
  );
}
