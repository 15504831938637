import { MinusIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Stack,
} from "@chakra-ui/react";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { CreatedEventProps } from "./CreatedEventComponent";
import * as Yup from "yup";
import { SubmitButton } from "../components/SubmitButton";
import { useCallback, useState } from "react";
import { number } from "yup";
import { EventInput, registerEvent } from "../../api";

interface ComponentProps {
  setEvent: (event: CreatedEventProps) => void;
}

const CreateEventSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  date: Yup.date()
    .required("Required")
    .min(
      new Date(new Date().getDate() - 1),
      "Event date cannot be from the past!"
    ),
  limit: Yup.number()
    .integer("Limit must be an integer value.")
    .positive("Amount limit must be a number.")
    .moreThan(0, "Amount limit must be higher than 0.")
    .required("Required"),
  participants: Yup.array()
    .of(Yup.string().required().min(3).max(50))
    .min(3, "Minimum number of participants is 3.")
    .required("Required"),
});

export const CreateEventComponent = (props: ComponentProps) => {
  const { setEvent } = props;

  const onSubmit = useCallback(
    (values: EventInput) => {
      registerEvent(values)
        .then(response => {
          setEvent({
            eventId: response?.id!,
            eventName: response?.name!
          })
        })
      // registerEvent({ variables: { ...values } }).then((event) =>
      //   setEvent({
      //     eventId: event.data?.registerEvent?.id!,
      //     eventName: event.data?.registerEvent?.name!,
      //   })
      // );
    },
    [setEvent]
  );

  const formik = useFormik({
    initialValues: {
      name: "",
      date: new Date().toDateString(),
      limit: 10,
      participants: ["", "", ""],
    },
    validationSchema: CreateEventSchema,
    validateOnChange: true,
    onSubmit: onSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Center>
          <Box>
            <FormControl
              my="3"
              isRequired
              isInvalid={(formik.errors.name && formik.touched.name) || false}
            >
              <FormLabel>Event name</FormLabel>
              <Input
                type="text"
                id="name"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
              <FormHelperText>
                Event name that will be visible for all participants.
              </FormHelperText>
            </FormControl>
            <FormControl
              my="3"
              isRequired
              isInvalid={Boolean(formik.errors.date && formik.touched.date)}
            >
              <FormLabel>Event date</FormLabel>
              <Input
                type="date"
                id="date"
                onChange={formik.handleChange}
                value={formik.values.date}
              />
              <FormErrorMessage>{formik.errors.date}</FormErrorMessage>
              <FormHelperText>
                Event date that will be visible for all participants.
              </FormHelperText>
            </FormControl>
            <FormControl
              my="3"
              isRequired
              isInvalid={Boolean(formik.errors.limit && formik.touched.limit)}
            >
              <FormLabel>Maximum amount that can be spent on a gift</FormLabel>
              <Input
                type="number"
                id="limit"
                onChange={formik.handleChange}
                value={formik.values.limit}
              />
              <FormErrorMessage>{formik.errors.limit}</FormErrorMessage>
              <FormHelperText>
                Maximum amount that can be spent on present by each participant.
              </FormHelperText>
            </FormControl>
          </Box>
        </Center>
        <Center>
          <Box w="100%">
            <FormControl
              isRequired
              isInvalid={
                (formik.errors.participants && formik.touched.participants) ||
                false
              }
            >
              <FieldArray
                name="participants"
                render={(arrayHelpers) => (
                  <Box>
                    <FormLabel>Participants</FormLabel>
                    { Boolean(formik.values?.participants.length) && (
                      formik.values.participants.map((participant, index) => (
                        <Stack direction="row" my="3" key={index}>
                          <Input
                            name={`participants.${index}`}
                            type="text"
                            value={participant}
                            onChange={formik.handleChange}
                          />
                          <IconButton
                            colorScheme="red"
                            aria-label="Remove participant"
                            icon={<MinusIcon />}
                            onClick={() => arrayHelpers.remove(index)}
                          />
                        </Stack>
                      ))
                    )}
                    <Button
                      my="3"
                      // align="end"
                      size="md"
                      variant="link"
                      onClick={() => arrayHelpers.push("")}
                    >
                      + Add participant
                    </Button>
                  </Box>
                )}
              />
              <FormErrorMessage>{formik.errors.participants}</FormErrorMessage>
            </FormControl>
          </Box>
        </Center>
        <Center>
          <SubmitButton name="Let it snow!" />
        </Center>
      </form>
    </FormikProvider>
  );
};
