export const ROUTES = {
  ROOT: "/",
  CREATE_EVENT: "/events",
  EVENT_DETAILS: "/events/:eventId",
  EVENT_CHECK: "/events/:eventId/check",
  EVENT_CHECK_USER: "/events/:eventId/check/:userId",
};

export const toMainPage = () => {
  return ROUTES.ROOT;
}

export const toEvent = (eventId?: string) => {
  return "/events/" + eventId;
}

export const toEventCheck = (eventId?: string) => {
  return "/events/" + eventId + "/check";
};

export const toEventUserCheck = (eventId?: string, userId?: string) => {
  return "/events/" + eventId + "/check/" + userId;
};