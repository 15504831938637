import { Box, Center } from "@chakra-ui/layout";
import { Button, Select } from "@chakra-ui/react";
import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES, toEventUserCheck } from "../../routes";
import { EventNameHeader } from "../components/EventNameHeader";
import { getEvent, Event } from "../../api";
import { Loading } from "../components/Loading";

export const ChooseParticipantPage = () => {
  const navigate = useNavigate();
  const { eventId } = useParams();
  const [event, setEvent] = useState<Event>()

  const onSubmit = useCallback(({ userId }: { userId: string}) => {
    navigate(toEventUserCheck(eventId, userId));
  }, [navigate, eventId]);

  const { handleChange, handleSubmit } = useFormik({
    initialValues: {
      userId: "",
    },
    onSubmit: onSubmit,
  });

  useEffect(() => {
    getEvent(eventId!).then(setEvent)
  }, [eventId])

  if (!event) {
    return <Loading />;
  }

  return (
    <Box>
      <EventNameHeader eventName={event.name} />
      <Box mx="6">
        <form onSubmit={handleSubmit}>
          <Select
            id="userId"
            placeholder="Select your name"
            isRequired
            onChange={handleChange}
          >
            {event.participants
              .filter((participant) => !participant.checked_pair)
              .map((participant) => {
                return (
                  <option value={participant.id}>{participant.name}</option>
                );
              })}
          </Select>
          <Center>
            <Button size="lg" m="6" type="submit">
              Check your Secret Santa
            </Button>
          </Center>
        </form>
      </Box>
    </Box>
  );
};
