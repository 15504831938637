import { Center, Text } from "@chakra-ui/layout";
import { Badge, Spacer } from "@chakra-ui/react";

interface ComponentProps {
  checked: boolean;
  name: string
}

export const ParticipantRow = (props: ComponentProps) => {
  const { checked, name } = props;
  return (
    <Center>
      <Text>{name}</Text>
      <Spacer />
      <Badge variant="subtle" colorScheme={checked ? "green" : "red"}>
        {checked ? "CHECKED" : "WAITING"}
      </Badge>
    </Center>
  );
}
