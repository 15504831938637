import { useCallback, useEffect, useState } from "react";
import { ROUTES } from "../../routes";
import {
  CreatedEventComponent,
  CreatedEventProps,
} from "./CreatedEventComponent";
import { CreateEventComponent } from "./CreateEventComponent";

export const CreateEventPage = () => {
  const [createdEvent, setCreatedEvent] = useState<CreatedEventProps | null>(
    null
  );

  const setEventCallback = useCallback(
    (createdEvent: CreatedEventProps) => {
      setCreatedEvent(createdEvent);
    },
    [setCreatedEvent]
  );

  if (createdEvent == null) {
    return <CreateEventComponent setEvent={setEventCallback} />;
  }

  return <CreatedEventComponent {...createdEvent} />;
};
